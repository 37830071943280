<template>
  <div class="border-bottom p-1">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Akcesje</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista akcesji</h1>    

    <!-- 
<input
    type="text"
    class="form-control "
    v-model="filterValue"
    keyup="doFilter"
    placeholder="Filtr: wpisz fragment tytułu, wydawcy, miejsa wydania, ISBN"
  />
  -->
    <div class="row">
      <div class="col-md-3">
        Szukaj<br/>
        <input
          type="text"
          class="form-control"
          v-model="filterValue"
          @keydown.enter="fetchEntries"
          placeholder="Tekst do wyszukania"
        />
      </div>
      
      <div class="col-md-3">
        W polu<br/>
        <select type="text" class="form-select" v-model="filterType">
          <option value="">-dowolny-</option>
          <option value="prot_akc">protokół akcesji</option>
          <option value="autor_woluminu">autor woluminu</option>
          <option value="tytul_woluminu">tytuł woluminu</option>
          <option value="miejsce_wydania">miejsce wydania</option>
          <option value="wydawnictwo">wydawnictwo</option>
          <option value="isbn">isbn</option>
        </select>
      </div>

      <div class="col-md-2">
        Rok<br/>
        <select class="form-select" v-model="selectedYear">  <!-- @change="handleChange" -->
          <option value="">-dowolny-</option>
          <option v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
      <div class="col-md-2">
        Miesiąc<br/>
        <select class="form-select" v-model="selectedMonth" > <!-- @change="handleChange" -->
          <option value="0">-dowolny-</option>
          <option value="1">styczeń</option>
          <option value="2">luty</option>
          <option value="3">marzec</option>
          <option value="4">kwiecień</option>
          <option value="5">maj</option>
          <option value="6">czerwiec</option>
          <option value="7">lipiec</option>
          <option value="8">sierpień</option>
          <option value="9">wrzesień</option>
          <option value="10">październik</option>
          <option value="11">listopad</option>
          <option value="12">grudzień</option>
        </select>
      </div>

      <div class="col-md-1">
        <input type="checkbox" v-model="showAll" /> pokaż wszyskie
      </div>
      <input
        type="button"
        class="btn btn-outline-primary col-md-1"
        value="Szukaj"
        @click="fetchEntries()"
      />
    </div>
    <small>Lista prezentuje wyniki wyszukiwania, ilość wierszy: {{ numberOfRows }} <span v-if="totalRows>0">z {{ totalRows }}</span></small>
  </div>
  

  <div id="content">
    <div class="row border-bottom" v-for="entry in entries" :key="entry.id_akc">
      <div class="col-md-2" title="Protokół akcesji &#10;ISBN">
        {{ entry.prot_akc }}<br />{{ entry.isbn }}
      </div>

      <div
        class="col-md-6"
        title="Tytuł | Seria&#10;Wydawnictwo | miejsce wydania | rok wydania "
      >
        <router-link
          v-if="data == null"
          class="fw-bold"
          :to="{ name: 'EntryDetails', params: { id: entry.id_akc } }"
          >{{ entry.tytul_woluminu }}</router-link
        >
        <i v-if="entry.nazwa_serii != null && entry.nazwa_serii.length > 1" >&nbsp;{{ entry.nazwa_serii }}</i
        >
        <span
          v-if="data != null"
          @click="selectItem(entry)"
          class="text-primary hand fw-bold"
          >{{ entry.tytul_woluminu }} [wybierz]</span
        >

        <br />{{ entry.wydawnictwo }} | {{ entry.miejsce_wydania }} |
        {{ entry.rok_wydania }}
      </div>
      <div class="col-md-4" title="Autor woluminu &#10;Data wprowadzenia">
        <i>{{ entry.autor_woluminu }}</i
        ><br />{{ entry.data }}
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast } from "@/services/Dialogs"; //customYesNo

export default {
  name: "EntryList",
  data() {
    return {
      entries: [],
      filterValue: "",
      showAll: false,
      filterType: "",
      numberOfRows: null,
      totalRows : null,
      years : [],
      selectedYear : "",// new Date().getFullYear(),
      selectedMonth : 0//new Date().getMonth()+1,
    };
  },
  props: {
    //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null,
    },
  },
  created() {    
    const currentYear = new Date().getFullYear();
    for (var a = currentYear; a > 2009; a--) {
      this.years.push(a);
    }
    this.fetchEntries();
  },
  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchEntries();
    },

    selectItem(item) {
      if (this.data.callback != null) this.data.callback(item);
    },

    fetchEntries() {
      var action = "list";
      if (this.data != null && this.data.action == "select-without-protocol") {
        action = "select-without-protocol";
      }
      fetchData(
        "?section=entry&action=" + action,
        {
          filter: this.filterValue,
          filterType: this.filterType,
          showAll: this.showAll ? "y" : "n",
          year : this.selectedYear, 
          month: this.selectedMonth,
        },
        (res, info) => {
          this.entries = res;
          this.numberOfRows = res.length;
          this.totalRows = Number(info);
        },
        (err) => {
          if (err == null) err = "Błąd pobierania danych, zaloguj się ponownie";
          showToast(err, false);
        }
      );
    },
  },
};
</script>
