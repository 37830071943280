<template>
<div class="border-bottom p-1" v-if="data==null">
        <a href="/" class="me-2">Start</a>/        
        <router-link class="me-2" :to="{ name: 'DocumentList', params: { kwd:head.kwd } }">Dokumenty</router-link>/
        <span class="me-2">Dokument</span>
    </div>
    <h1 class="mt-4">{{getDocumentName()}}</h1>
    <div class="w-100 text-end">
        <button v-if="!isEditMode" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
        Edytuj
        </button>
        <button v-if="isEditMode && head.id_fv>0" @click="deleteDocument()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
        <button v-if="(isEditMode || isEditRemarks) && !autoSave" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
        <button v-if="isEditMode" @click="saveDocument()" class="btn btn-sm btn-outline-primary mb-3">
        Zapisz 
        </button>
    </div>

    <form>          
      <div class="row">
        <div class="mb-3 col-md-6">
            <label for="dokument_obcy" class="form-label" v-if="$route.params.kwd.toLowerCase()=='k'">Nr faktury</label>
            <input type="text" id="dokument_obcy" class="form-control"  v-if="$route.params.kwd.toLowerCase()=='k'" v-model="head.dokument_obcy" :readonly="!isEditMode" :disabled="!isEditMode">
            <br/>
            <label for="data_dokumentu" class="form-label">Dokument z dnia</label>
            <input type="date" id="data_dokumentu" class="form-control" v-model="head.data_dokumentu" :readonly="!isEditMode" :disabled="!isEditMode">        
        </div>
        <div class="mb-3 col-md-6 text-end">
          <label for="kwota_brutto_avista" class="form-label" v-if="isEditMode || true">Kwota brutto (kontrolnie)</label><br/>
              <input type="text" id="kwota_brutto_avista" class="form-control w-50"  v-model="head.kwota_brutto_avista" :readonly="!isEditMode" :disabled="!isEditMode"  style="display:initial; text-align:right; color:green;">
            <br/><br/>
            <p>Wartość netto: <b>{{head.wartosc_netto}} zł </b></p>
            <p>Wartość brutto: <b>{{head.wartosc_brutto}} zł</b></p>
            <p v-if="isEditMode || true" :style="{color: head.wartosc_brutto - head.kwota_brutto_avista == 0 ? 'green' : 'red'}">Wartość brutto (kontrolna): <b>{{(head.wartosc_brutto - head.kwota_brutto_avista).toFixed(2)}} zł</b></p>
        </div>
      </div>
      <div class="mb-3">        
        <label for="nazwa_wystawcy" class="form-label">Nazwa /<i>id: {{head.id_knt}}</i>/</label>
        <span v-if="isEditMode" class="btn btn-sm btn-outline-primary ms-3 hand" @click="showDisplayKnt(head)">wybierz z listy</span>  
        <input type="text" id="nazwa_wystawcy" class="form-control" v-model="head.nazwa_wystawcy" :readonly="!isEditMode || !head.id_knt" :disabled="!isEditMode || !head.id_knt">
      </div>
      <div class="mb-3">
        <label for="adres_wystawcy" class="form-label">Adres</label>
        <input type="text" id="adres_wystawcy" class="form-control" v-model="head.adres_wystawcy" :readonly="!isEditMode" :disabled="!isEditMode  || !head.id_knt">        
      </div>
      <div class="mb-3">
        <label for="adres_wystawcy_cd" class="form-label">Kod pocztowy / miasto</label>
        <input type="text" id="adres_wystawcy_cd" class="form-control" v-model="head.adres_wystawcy_cd" :readonly="!isEditMode" :disabled="!isEditMode  || !head.id_knt">        
      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
            <label for="nip" class="form-label">NIP</label>
            <input type="text" id="nip" class="form-control" v-model="head.nip" :readonly="!isEditMode" :disabled="!isEditMode  || !head.id_knt">        
        </div>
        <div class="mb-3 col-md-6">
            <label for="platnik_vat" class="form-label">Płatnik VAT</label>
            <select type="text" id="nip" class="form-select" v-model="head.platnik_vat" :readonly="!isEditMode" :disabled="!isEditMode  || !head.id_knt">        
            <option value="0">Nie</option>
            <option value="1">Tak</option>
            </select>
        </div>
      </div>
      <div class="mb-3">
        <label for="uwagi" class="form-label">Uwagi</label>
        <textarea id="uwagi" class="form-control" v-model="head.uwagi" :readonly="!isEditMode" :disabled="!isEditMode"></textarea>        
      </div>
    </form>

    <div id="entries" style="font-size:1em">
        <div class="row border-bottom py-1"  v-for="(entry, index) in head.akcesje" :key="entry.id_akc">
            <div class="col-md-8" title="Autor, tytuł&#10;Wydawnictwo, miejsce wydania, rok wydania">
                <b>{{index+1}}</b> | {{entry.autor_woluminu}} | <i>{{entry.tytul_woluminu}}</i><br/> <span class="text-muted">Wydawnictwo: </span>{{ entry.wydawnictwo }} | 
                <span class="text-muted">Miejsce wydania:</span> {{entry.miejsce_wydania}}, {{entry.rok_wydania}}
                <span v-if="entry.prot_akc" class="text-muted"> | Nr akcesji: </span> {{entry.prot_akc}}
                <br/>
                  <span class="text-primary hand" @click="showDisplayEntry(entry)">szczegóły</span>  
                  <span v-if="isEditMode"> | 
                    <span class="text-primary hand" @click="showEditEntry(entry)">edytuj pozycję</span> | 
                    <span class="text-primary hand" @click="duplicateEntry(entry)">duplikuj pozycję</span> |
                    <span class="text-primary hand" @click="deleteEntry(entry)">usuń pozycję</span> 
                  </span>        
            </div>
            <div class="col-md-4 text-end">
              Cena brutto:  {{entry.cena_jednostkowa_brutto}}<br/>
              VAT: {{getStawkaVat(entry)}}<br/>
              Cena netto: {{entry.cena_jednostkowa_netto}}
            </div>
        </div>
        <div class="w-100 text-end" v-if="isEditMode">
          <span class="btn btn-sm btn-outline-primary my-2" @click="newEntry()">dodaj pozycję</span>
        </div>
        
    </div>
    

     <br/><br/>
     <div class="w-100 text-end">
        <button v-if="!isEditMode" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
        Edytuj
        </button>
        <button v-if="isEditMode && head.id_fv>0" @click="deleteDocument()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
        <button v-if="(isEditMode || isEditRemarks) && !autoSave" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
        <button v-if="isEditMode" @click="saveDocument()" class="btn btn-sm btn-outline-primary mb-3">
        Zapisz 
        </button>
    </div>
    

    <div class="overlay" v-if="isShowEditEntry">
      <div class="popup">
        <div class="w-100 text-end">
          <button v-if="isEditMode" @click="abortPopup()" class="btn btn-sm btn-outline-success me-2">Anuluj</button>
          <button v-if="isEditMode" @click="closePopup" class="btn btn-sm btn-outline-primary">
            Zapisz i zamknij
          </button>
          <button v-if="!isEditMode" @click="abortPopup" class="btn btn-sm btn-outline-primary">
            Zamknij
          </button>
        </div>
          <EntryDetails :data="objectToPass" />

          <div class="w-100 text-end">
          <button v-if="isEditMode" @click="abortPopup()" class="btn btn-sm btn-outline-success me-2">Anuluj</button>
          <button v-if="isEditMode" @click="closePopup" class="btn btn-sm btn-outline-primary">
            Zapisz i zamknij
          </button>
          <button v-if="!isEditMode" @click="abortPopup" class="btn btn-sm btn-outline-primary">
            Zamknij
          </button>
        </div>

      </div>
    </div>

    <div class="overlay" v-if="isShowFindKnt">
      <div class="popup">
        <div class="w-100 text-end">
          <button @click="closePopupKnt" class="btn btn-sm btn-outline-primary">
            Zamknij
          </button>
        </div>
          <ClientList :data="objectToPass" />
      </div>
    </div>
  
</template>
<script>
import {fetchData} from "@/services/FetchMachine";
import EntryDetails from '../components/EntryDetails.vue'
import ClientList from '../components/ClientList.vue'
import {showToast,customYesNo} from "@/services/Dialogs"
export default {
  name: "DocumentDetails",
  data() {
    return {
        isEditMode: false,
        isShowEditEntry : false,
        isShowFindKnt : false,
        deletedItems : [],
        objectToPass : null,        
        head: {},        
        autoSave : true
    };
  },

  components: {
    EntryDetails,
    ClientList
  },
  
  created() {
    if (this.$route.params.id>0)
      this.fetchDocument(this.$route.params.id);
    else 
      this.newDocument();
  },

  methods: {
    setEditMode(v) {
      this.isEditMode = v;
    },

    abortPopup() {
      this.isShowEditEntry = false;
    },

    closePopup() {
        this.isShowEditEntry = false;
        this.calculateTotalNettoBrutto();
        if (this.autoSave) {
          this.saveDocument(()=>{this.setEditMode(true);});
        }
    },

    closePopupKnt() {
        this.isShowFindKnt = false;
    },

    calculateTotalNettoBrutto() {
      var n = 0;
      var b = 0;
      this.head.akcesje.forEach(e => {        
        n = n + Number( e.wartosc_netto );
        b = b + Number( e.wartosc_brutto );
      });
      this.head.wartosc_netto = n.toFixed(2);
      this.head.wartosc_brutto = b.toFixed(2);
    },

    abortChanges() {
      location.reload();
    },

    deleteDocument() {
      customYesNo("Potwierdź czy chcesz usunąć dokument wraz ze wszystkimi pozycjami?",()=>{

        fetchData(
        "?section=document&action=delete", {id:this.head.id_fv, mode:this.head.kwd},
        (res) => {
          console.log(res);
          location.href= "/documents/"+this.$route.params.kwd;
        }, 
        (err) => {
          console.log(err);
          showToast(err, false, ()=>{location.reload();});          
          }
          );      
      });
    },

    getStawkaVat(entry) {
        var v = entry.stawka_vat;
        if (v>0) return String(v) + "%";
        if (v==null || v===-9) return "--";
        if (v==-1) return "np";
        if (v==-2) return "zw";
        return v;
    },

    showEditEntry(entry) {
        console.log(entry);
        this.objectToPass = {action:"edit", entry: entry, platnikVat: this.head.platnik_vat}
        this.isShowEditEntry = true;
    },

    showDisplayEntry(entry) {
        console.log(entry);
        this.objectToPass = {action:"show", entry: entry, platnikVat: this.head.platnik_vat}
        this.isShowEditEntry = true;
    },

    showDisplayKnt(head) {
        this.objectToPass = {action:"select", head: head,  callback: (c)=>{ 
            this.head.adres_wystawcy =  c.adres_wystawcy;
            this.head.adres_wystawcy_cd = c.adres_wystawcy_cd;
            this.head.id_knt=c.id_knt;
            this.head.nazwa_wystawcy=c.nazwa_wystawcy;
            this.head.nip=c.nip;
            this.head.platnik_vat = c.platnik_vat;
            this.isShowFindKnt = false;  
        }};
        this.isShowFindKnt = true;
    },
    
    duplicateEntry(entry) {
      var copy = Object.assign({}, entry);
      copy.id_akc = -Date.now();
      copy.nr_akcesji	 = "";
      copy.prot_akc	 = "";
      copy.data	 = "";
      copy.ksiega = "";
      this.head.akcesje.push(copy);      
      this.calculateTotalNettoBrutto();
    },

    deleteEntry(entry) {
      customYesNo("Potwierdź czy chcesz usunąć pozycję",()=>{
        this.deletedItems.push(entry.id_akc);
        this.head.akcesje = this.head.akcesje.filter(item => item.id_akc !== entry.id_akc);
        this.calculateTotalNettoBrutto();
      });
    },

    newEntry() {      
      if (this.autoSave && this.head.id_knt<1)   {
        showToast("Przed dodaniem pozycji proszę wybrać kontrahenta");
        return;
      }
      fetchData(
        "?section=entry&action=empty&v=2", {},
        (empty) => {
          this.head.akcesje.push(empty);
          this.objectToPass = {action:"edit", entry: empty, platnikVat: this.head.platnik_vat | 0};
          this.isShowEditEntry = true;          
        } );      
    },

    getDocumentName() {
      if (this.$route.params.kwd=="k") return "Kontrahent";
      if (this.$route.params.kwd=="d") return "Darczyńca";
      if (this.$route.params.kwd=="w") return "Instytucja wymiany";
      return "";
    },

    newDocument() {
      fetchData("?section=document&action=empty",{mode: this.$route.params.kwd},
       res=>{
        this.head = res;
        this.isEditMode = true;
       });
    },

    saveDocument(done = null) {
      this.calculateTotalNettoBrutto();
      this.setEditMode(false);
      this.head["_deleted"] = this.deletedItems;
      fetchData("?section=document&action=save",{mode:this.head.kwd, document: this.head},
        (res)=>{
          console.log(res);
          this.head=res;
          showToast("Zapisane", true); 
          if (done) done();
        },
        (res)=>{
          console.log(res);
          if (res=="KNT_ID:REQ") {
            showToast("Błąd zapisu, proszę wybrać kontrahenta", false);
            this.setEditMode(true);
          }
          else {
            showToast(res, false);
            this.setEditMode(true);
            if (this.head.id_fv>0) this.fetchDocument(this.head.id_fv);
          }
        }        
      )
      
      //this.setEditMode(false);
    },    

    fetchDocument(id = this.$route.params.id) {
      if (this.head.kwd==null) this.head.kwd = this.$route.params.kwd;
      fetchData(
        "?section=document&action=details",
        { mode:this.head.kwd,id: id },
        (res) => {
          this.head = res;
          if (this.head.platnik_vat == null) this.head.platnik_vat=0;
          this.calculateTotalNettoBrutto();
        },
        () => {            
            showToast("Błąd pobierania danych", false);
          //document.getElementById("content").innerHtml = "X";
          //$("#clients").html("Błąd");
        }
      );
    },

  },
};
</script>

			
	