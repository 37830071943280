<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        
        <a href="/"><img src="/assets/logo.png" id="logo"/></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">            
            <li class="nav-item">
              <router-link to="/entries" v-if="canSee('grm')" class="nav-link">Akcesje</router-link>
            </li>
            <li class="nav-item">
              <a href="/documents/k" v-if="canSee('grm')" class="nav-link">Kupno</a>
            </li>
            <li class="nav-item">
              <a href="/documents/d" v-if="canSee('grm')" class="nav-link">Dary</a>
            </li>
            <li class="nav-item">
              <a href="/documents/w" v-if="canSee('grm')" class="nav-link">Wymiana</a>
            </li>

            <li class="nav-item">
              <router-link to="/protocols" v-if="canSee('grm opr piu mag inw kat')" class="nav-link">Protokoły</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/clients" v-if="canSee('grm')" class="nav-link">Kontrahenci</router-link>
            </li>

            <li class="nav-item">
              <router-link to="/reports" v-if="canSee('grm')" class="nav-link">Raporty</router-link>
            </li>
            
            <li class="nav-item d-none">
              <a href="/series" v-if="canSee('grm')" class="nav-link">Serie</a>
            </li>
            <li class="nav-item">
              <a href="/publishers" v-if="canSee('grm')" class="nav-link">Wydawcy</a>
            </li>
            
            <li class="nav-item">
              <router-link to="/reports" v-if="canSee('grm opr piu mag inw kat adm')" @click="logout" class="nav-link">Wyloguj</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-2" style="min-height:70vh">
      
      <router-view v-slot="{ Component, route }">
      <keep-alive :include="cachedViews">
        <component :is="Component" :key="route.name" />
      </keep-alive>
    </router-view>
    </div>
  </div>

  <div class="mt-5 py-5 text-light bg-dark">
    <div class="container mt-5">
      <div class="text-center">
        (c) 2024<br /><br />
        Zakład Narodowy im. Ossolińskich <!--Ossoliński National Institute    -->
      </div>
    
      <div class="row">
        <div class="col-md text-center">ul. Szewska 37<br/>
  50–139 Wrocław</div>
        <div class="col-md text-center">telefon: 71 344 44 71<br/>
  email: ossolineum@ossolineum.pl<br/>
  NIP: 897-10-82-476
  </div>
        <div class="col-md text-center">
        
        <span @click="logout" class="hand">wyloguj {{info()}}</span>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import {fetchData} from "@/services/FetchMachine";

export default {
  name: "App",

  computed: {
    cachedViews() {
      return ['ClientList', 'EntryList','DocumentList','SeriesList']; // Only cache List and Detail components
    }
  },

  methods: {

    info() {
      return localStorage.getItem("i") +"/" + localStorage.getItem("r");
      //return "x";
    },

     canSee(role) {
      if (localStorage.getItem('r')!=null && role.includes(localStorage.getItem('r'))) return true; else return false;
    },

    logout() {
      fetchData("?section=login&action=logout", null,(x) => { 
        console.log(x);
        localStorage.removeItem("i");
        localStorage.removeItem("r");
        document.cookie.split(";").forEach(function(c) {
          document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        });
        location.href="/";
      }, null, true);
    }
  }
};
</script>

<style>
/* Add global styles here */
</style>

