<template>
  <div class="border-bottom p-1" v-if="data == null">
    <router-link to="/" class="me-2">Start</router-link>/
    <router-link to="/clients" class="me-2">Kontrahenci</router-link>/
    <span class="me-2">Kontrahent</span>
  </div>
  <h1 class="mt-4">Kontrahent</h1>

  <div class="w-100 text-end">
    <button v-if="!isEditMode" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
      Edytuj
    </button>
    <button v-if="isEditMode" @click="deleteClient()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
    <button v-if="isEditMode" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
    <button v-if="isEditMode" @click="saveClient()" class="btn btn-sm btn-outline-primary mb-3">Zapisz</button>
  </div>
  <form>
    <div class="mb-3">
      <label for="nazwa_wystawcy" class="form-label">Nazwa</label>
      <input type="text" id="nazwa_wystawcy" class="form-control" v-model="client.nazwa_wystawcy"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="mb-3">
      <label for="adres_wystawcy" class="form-label">Adres</label>
      <input type="text" id="adres_wystawcy" class="form-control" v-model="client.adres_wystawcy"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="mb-3">
      <label for="adres_wystawcy_cd" class="form-label">Kod pocztowy / miasto</label>
      <input type="text" id="adres_wystawcy_cd" class="form-control" v-model="client.adres_wystawcy_cd"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="nip" class="form-label">NIP</label>
        <input type="text" id="nip" class="form-control" v-model="client.nip" :readonly="!isEditMode"
          :disabled="!isEditMode">
      </div>
      <div class="mb-3 col-md-6">
        <label for="platnik_vat" class="form-label">Płatnik VAT</label>
        <select type="text" id="nip" class="form-select" v-model="client.platnik_vat" :readonly="!isEditMode"
          :disabled="!isEditMode">
          <option value="0">Nie</option>
          <option value="1">Tak</option>
        </select>
      </div>
    </div>
    <div class="mb-3">
      <label for="uwagi" class="form-label">Uwagi</label>
      <textarea id="uwagi" class="form-control" v-model="client.uwagi" :readonly="!isEditMode"
        :disabled="!isEditMode"></textarea>
    </div>
  </form>
  <div>
    <h2>Dokumenty nabycia</h2>
    <div class="row border-bottom" v-for="entry in getDocs()" :key="entry.nabycie_id_fvat">
      <div class="col-md-8">
        <router-link v-if="entry.nabycie_id_fvat > 0"
          :to="{ name: 'DocumentDetails', params: { kwd: entry.kwd, id: entry.nabycie_id_fvat } }">Przyjęty poprzez
          dokument
          {{ entry.dokument_obcy }} od {{ entry.nazwa_wystawcy }}</router-link>
      </div>
      <div class="col-md-4 text-end">Brutto: {{ entry.wartosc_brutto	 }}</div>
    </div>
    <div class="row"><div class="col-12 text-end">
    Łącznie brutto: {{sumDocs().toFixed(2)  }}</div>
    </div>

  </div>
  <h2>Akcesje</h2>
  
  <div id="entries" style="font-size:0.8em">
    <div class="row border-bottom" v-for="entry in entries" :key="entry.id_akc">
      <div class="col-md-8">{{ entry.tytul_woluminu }}<br />{{ entry.autor_woluminu }}</div>
      <div class="col-md-4">Data akcesji {{ entry.data }}<br />
        <router-link :to="{ name: 'EntryDetails', params: { id: entry.id_akc } }">Nr akcesji: {{ entry.prot_akc
          }}</router-link>
        <br />
        <router-link v-if="entry.nabycie_id_fvat > 0"
          :to="{ name: 'DocumentDetails', params: { kwd: entry.kwd, id: entry.nabycie_id_fvat } }">
          Dokument nabycia {{ entry.dokument_obcy }}<br/></router-link>
          Wartość brutto: {{ entry.wartosc_brutto	 }}
      </div>

    </div>
    
  </div>
  

</template>
<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast, customYesNo } from "@/services/Dialogs" //customYesNo

export default {
  name: "ClientDetails",
  data() {
    return {
      isEditMode: false,
      client: {},
      entries: []
    };
  },



  created() {
    this.fetchDetails();
    this.fetchEntries();
  },
  methods: {
    setEditMode(v) {
      this.isEditMode = v;
    },

    getDocs() {
      let res = Array.from(
        new Map(
          this.entries.map(item => [
            `${item.nabycie_id_fvat}_${item.kwd}_${item.wartosc_netto}_${item.wartosc_brutto}_${item.nazwa_wystawcy}_${item.dokument_obcy}`,
            {
              nabycie_id_fvat: item.nabycie_id_fvat,
              kwd: item.kwd,
              wartosc_netto: item.wartosc_netto,
              wartosc_brutto: item.wartosc_brutto,
              nazwa_wystawcy: item.nazwa_wystawcy,
              dokument_obcy: item.dokument_obcy
            }
          ])
        ).values());
      return res;
    },
    sumDocs() {
      let x = this.getDocs();
      let sum = 0;
      x.forEach(o=>{        
        sum += Number(o.wartosc_brutto);
      });
      return sum;
    },
    saveClient() {
      fetchData(
        "?section=client&action=save",
        { client: this.client },
        (res) => {
          this.client = res;
          showToast("Dane zapisane", true);
        },
        () => {
          showToast("Błąd zapisu", false);
        }
      );
    },

    abortChanges() {
      this.setEditMode(false);
      this.fetchDetails(this.client.client);
    },
    deleteClient() {
      customYesNo("Potwierdź czy chcesz usunąć klienta?", () => {

        fetchData(
          "?section=client&action=delete", { id: this.client.client },
          (res) => {
            console.log(res);
            location.href = "/clients";
          },
          (err) => {
            console.log(err);
            showToast(err, false, () => { location.reload(); });
          }
        );
      });
    },

    fetchDetails(id = this.$route.params.id) {
      var action = "details";
      console.log("id", id);

      if (id == null || id == "0") {
        id = 0;
        this.isEditMode = true;
        action = "empty";
      }
      fetchData(
        "?section=client&action=" + action,
        { id: id },
        (res) => {
          this.client = res;
        },
        () => {
          showToast("Błąd pobierania danych", false);
        }
      );
    },

    fetchEntries() {
      var id = this.$route.params.id;
      if (id < 1) return;
      fetchData(
        "?section=entry&action=entries-for-client",
        { kntId: this.$route.params.id },
        (res) => {
          this.entries = res;
        },
        () => { }
      );

    }

  },
};
</script>
