
export function fetchData(method, params, onSuccess, onError, disableSessionCheck=false) {
    //var address = "http://192.168.77.173/Ossolineum/api/";

    if (!disableSessionCheck) {
        checkSession();
    }
    var address = window.ENDPOINT;
    var requestOptions = null;
    if (params != null) {
        requestOptions = {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(params)
        }
    }
     else {
        requestOptions = {
            credentials: 'include',
            method: "GET"}
    }

    document.getElementById('loader').style.display = 'block';
    fetch(
        address + method + "&" + Date.now(),
        requestOptions
    )
        .then((response) => response.json())
        .then((result) => {
            document.getElementById('loader').style.display = 'none';
            if (result.result == 1) {
                if (onSuccess) onSuccess(result.data, result.info);
            } else {
                if (onError) onError(result.data);
            }

        })
        .catch((error) => {
            document.getElementById('loader').style.display = 'none';
            console.log(error);
            if (onError) onError(-1);
        });
}

export function checkSession() {
    //var session = localStorage.getItem("session");
    var address = window.ENDPOINT;
    address = address + "?section=login&action=check-session&"+Date.now();
    //console.log("address",address);
    try {
        //if (session==null) throw new Error("no session");
        if (localStorage.getItem("i")==null)  location.href="/login"
        fetch(address, { credentials: 'include' }).then((response) => response.json()).then((result) => {
            console.log(result);
            if (result.result!==1) location.href="/login";
        });
    } catch (error) {
        console.log(error);
        location.href="/login";        
    }
}

//export default fetchData, checkSession;
