<template id="content">
  <div class="border-bottom p-1" v-if="data == null">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Serie</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista serii</h1>
    <small>Lista wyników, ilość wierszy: {{numberOfRows}}</small>

    <div class="row">
      <div class="col-md-6">    
      <input
        type="text"
        class="form-control"
        v-model="filterValue"      
        placeholder="Filtr: wpisz fragment tytułu serii, tomu lub wydawnictwa"
      />
      </div>
 
      <div class="col-md-3">
        <select type="text" class="form-select" v-model="filterType">
          <option value="">--wszystkie--</option>
          <option value="tytul_serii">tytuł serii</option>
          <option value="wydawnictwa.nazwa">nazwa wydawnictwa</option>
          <option value="tytul_tomu">tytuł tomu</option>
        </select>
      </div>
    
      
      <div class="col-md-2">
        <input type='checkbox' v-model="showAll"/> pokaż wszyskie
        </div>
    <input type="button" class="btn btn-outline-primary col-md-1" value="Szukaj" @click="fetchSeries()"/>
    </div>  
</div>


  

  <div id="series">
    <div class="w-100 text-end mb-2">      
      <router-link v-if="data==null" class="btn btn-sm btn-outline-primary" :to="{ name: 'SeriesDetails', params: { id: 0 } }">Utwórz nowy</router-link>
    </div>
    <div v-if="edited === 0" class="p-3 border my-3">
      Tworzenie nowego elementu"
      <div class="col-md-10">
        <input
          type="text"
          maxlength="200"
          id="tytul_serii"
          class="w-75"
          placeholder="Tytuł serii"
          v-model="serie.tytul_serii"
        />
        <br/>
          {{ serie.nazwa_wydawnictwa }} 
          <span @click="showDisplayPulblisher(serie)" class="text-primary hand"
            >[wybierz]</span>

      </div>
      <div class="col-md-2">
        <span @click="save(serie)" class="text-primary hand">Ok</span>
        <span @click="cancel()" class="text-secondary hand ms-3">anuluj</span>
      </div>
    </div>

    <div class="row border-bottom" v-for="serie in series" :key="serie.id_seria">
      <template v-if="edited != serie.id_seria">
        <div class="col-md-6" title="Nazwa">
           <router-link v-if="data==null" class="fw-bold" :to="{ name: 'SeriesDetails', params: { id: serie.id_seria } }">{{ serie.tytul_serii }}</router-link>
            <br/>
          <b>{{ serie.tytul_tomu }}</b>
        </div>
        <div class="col-md-6 text-end">
           {{ serie.nazwa_wydawnictwa }}
          
        </div>
      </template>
     
    </div>
  </div>

  <div class="overlay" v-if="isShowFindPublisher">
    <div class="popup">
      <div class="w-100 text-end">
        <button @click="closePopupKnt" class="btn btn-sm btn-outline-primary">
          Zamknij
        </button>
      </div>
      <PublisherComplex :data="objectToPass" />
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast, customYesNo } from "@/services/Dialogs";
import PublisherComplex from "../components/PublisherComplex.vue";

export default {
  components: {
    PublisherComplex,
  },

  name: "SeriesList",
  data() {
    return {
      series: [],
      serie: null,
      isError: false,
      filterValue: "",
      isShowFindPublisher: false,
      objectToPass: null,
      edited: null,
      filterType : "",
      numberOfRows : null,
      showAll : false
    };
  },

  props: {
    //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null,
    },
  },

  created() {
    this.fetchSeries();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchSeries();
    },

    /*createItem() {
      fetchData("?section=serie&action=empty", {}, (empty) => {
        this.serie = empty;
        this.serie.id_seria = 0;
        this.edited = 0;
      });
    },*/

    showDisplayPulblisher(obj) {
      this.objectToPass = {
        action: "select",
        callback: (c) => {
          console.log(c);
          this.isShowFindPublisher = false;
          obj.nazwa_wydawnictwa = c.nazwa;
          obj.id_wydawnictwa = c.id_wydawnictwa;
        },
      };
      this.isShowFindPublisher = true;
    },

    editInline(obj) {
      console.log(obj);
      this.edited = obj.id_seria;
    },

    cancel() {
      this.edited = null;
      this.fetchSeries();
    },

    deleteItem(obj) {
      console.log(obj);
      customYesNo("Potwierdź czy chcesz usunąć pozycję", () => {
        fetchData(
          "?section=serie&action=delete",
          { id: obj.id_seria },
          (res) => {
            console.log(res);
            this.fetchSeries();
          },
          (err) => {
            console.log(err);
            showToast(err, false, () => {
              location.reload();
            });
          }
        );
      });
    },

    save(obj) {
      console.log(obj);
      fetchData(
        "?section=serie&action=save",
        { serie: obj },
        (res) => {
          console.log(res);
          showToast("Wydawca zapisany", true, () => {
            this.edited = null;
            this.fetchSeries();
          });
        },
        (err) => {
          console.log(err);
          showToast(
            "Błąd zapisu serii. Upewnij się, że wypełniono dane prawidłowo",
            false
          );
        }
      );
    },

    selectserie(p) {
      if (this.data.callback != null) this.data.callback(p);
    },

    fetchSeries() {
      fetchData(
        "?section=serie&action=list",
        { filter: this.filterValue,
        filterType: this.filterType,
          showAll: this.showAll ? "y" : "n"
         },
        (res) => {
          this.series = res;
          this.numberOfRows = res.length;
        },
        (err) => {
          showToast(err, false);
        }
      );
    },
  },
};
</script>
