<template>
  <h1 class="my-4">Wybierz raport</h1>
  <!--report start -->
  <div class="row my-3 border bg-light rounded p-3">
    <div class="col-md-6 my-auto">
      <p class="fw-bold">Raport akcesji</p>
      Raport akcesji w okresie z podziałem na typ akcesji.
      <br /><br />
      <input
        type="text"
        v-model="title1"
        placeholder="Wprowadź tytuł raportu"
        style="width: 100%; margin-bottom: 12px"
      />
    </div>
    <div class="col-md-6 text-center my-auto" style="font-size: 0.8em">
      Dla kontahenta:
      <input type="text" disabled="disabled" v-model="kntNazwa" />
      <span class="text-primary hand" @click="showDisplayKnt()"> wybierz </span> |
      <span class="text-primary hand" @click="clearKnt()"> wyczyść </span>
      <br />
      <br />
      Data od (włącznie):
      <input
        type="date"
        id="start"
        name="start"
        min="2018-03"
        v-model="dateFrom"
      /><br /><br />
      Data do (włącznie):
      <input type="date" id="start" name="start" min="2018-03" v-model="dateTo" /><br />
      <br />
      Typ:
      <select v-model="entryType">
        <option value="*">wszyskie</option>
        <option value="k">kupno</option>
        <option value="d">dary</option>
        <option value="w">wymiana</option>
      </select>

      Pokaż kwoty:
      <select v-model="showAmount">
        <option value="1">tak</option>
        <option value="0">nie</option>
      </select>

      <br /><br />
      <span class="hand text-primary" @click="runRaportAkc()">[ uruchom raport ]</span>
    </div>
  </div>
  <!--report end -->

  <!--report start -->
  <div class="row my-3 border bg-light rounded p-3">
    <div class="col-md-6 my-auto">
      <p class="fw-bold">Raport zakupów</p>
      Raport zakupów w okresie.
      <br /><br />
      <input
        type="text"
        v-model="title2"
        placeholder="Wprowadź tytuł raportu"
        style="width: 100%; margin-bottom: 12px"
      />
    </div>
    <div class="col-md-6 text-center my-auto" style="font-size: 0.8em">
      Data od (włącznie):
      <input
        type="date"
        id="start"
        name="start"
        min="2018-03"
        v-model="dateFrom"
      /><br /><br />
      Data do (włącznie):
      <input type="date" id="start" name="start" min="2018-03" v-model="dateTo" /><br />
      <br />
      <span class="hand text-primary" @click="runRaportZak()">[ uruchom raport ]</span>
    </div>
  </div>
  <!--report end -->

  <!--report start -->
  <div class="row my-3 border bg-light rounded p-3">
    <div class="col-md-6 my-auto">
      <p class="fw-bold">Raport aktywności</p>
      Raport aktywności pracowników.
      <br /><br />
     
    </div>
    <div class="col-md-6 text-center my-auto" style="font-size: 0.8em">
      Raport za rok:
      <br/><br/>
       <input
        type="number"
        v-model="year"
        min="2000"
        max="2030"
        placeholder="Rok"
        style="width: 70px; margin-bottom: 12px"
      />
      <br />
      <span class="hand text-primary" @click="runRaportAkt()">[ uruchom raport ]</span>
    </div>
  </div>
  <!--report end -->

  <div class="overlay" v-if="isShowFindKnt">
    <div class="popup">
      <div class="w-100 text-end">
        <button @click="closePopupKnt" class="btn btn-sm btn-outline-primary">
          Zamknij
        </button>
      </div>
      <ClientList :data="objectToPass" />
    </div>
  </div>
</template>

<script>
import ClientList from "../components/ClientList.vue";
export default {
  name: "ReportList",

  data() {
    return {
      title1: "",
      title2: "",
      dateFrom: null,
      dateTo: null,
      year: 2024,
      isShowFindKnt: false,
      objectToPass: null,
      kntId: "",
      showAmount: 1,
      kntNazwa: "wszyscy",
      entryType: "*",
      /*reports: [
        {
          name: "Raport testowy",
          lead:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pharetra massa lorem, non consectetur nunc consectetur vitae. ",
          url: window.ENDPOINT + "?section=pdf",
        },
        {
          name: "Raport testowy 2 ",
          lead:
            "Dolor sit amet, ipsum consectetur adipiscing elit. Vivamus pharetra massa lorem, non consectetur vitae nunc consectetur. ",
          url: window.ENDPOINT + "?section=pdf",
        },
      ],*/
    };
  },

  components: {
    ClientList,
  },

  methods: {
    runRaportAkc() {
      location.href =
        window.ENDPOINT +
        `?section=pdf&report=entries&type=${this.entryType}&date-from=${
          this.dateFrom
        }&date-to=${this.dateTo}&knt-id=${this.kntId}&show-amount=${
          this.showAmount
        }&title=${encodeURI(this.title1)}`;
    },

    runRaportZak() {
      location.href =
        window.ENDPOINT +
        `?section=pdf&report=docs&date-from=${this.dateFrom}&date-to=${
          this.dateTo
        }&title=${encodeURI(this.title2)}`;
    },

    runRaportAkt() {
      location.href = window.ENDPOINT + `?section=pdf&report=activity&year=${this.year}`;
    },

    showDisplayKnt() {
      this.objectToPass = {
        action: "select",
        callback: (c) => {
          console.log(c);
          this.kntId = c.id_knt;
          this.kntNazwa = c.nazwa_wystawcy;
          this.isShowFindKnt = false;
        },
      };
      this.isShowFindKnt = true;
    },

    clearKnt() {
      this.kntId = "";
      this.kntNazwa = "wszyscy";
    },

    closePopupKnt() {
      this.isShowFindKnt = false;
    },
  },
};
</script>
