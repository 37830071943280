<template id="content">
  <div class="border-bottom p-1">
    <a href="/" class="me-2">Start</a>/
    <span class="me-2">Dokumenty</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista dokumentów [{{ this.$route.params.kwd }}]</h1>  

    <div class="row">
      <div class="col-md-6">
        Rok
        <select class="form-select" v-model="selectedYear" @change="handleChange">
          <option v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
      <div class="col-md-6">
        Miesiąc
        <select class="form-select" v-model="selectedMonth" @change="handleChange">
          <option value="0">-dowolny-</option>
          <option value="1">styczeń</option>
          <option value="2">luty</option>
          <option value="3">marzec</option>
          <option value="4">kwiecień</option>
          <option value="5">maj</option>
          <option value="6">czerwiec</option>
          <option value="7">lipiec</option>
          <option value="8">sierpień</option>
          <option value="9">wrzesień</option>
          <option value="10">październik</option>
          <option value="11">listopad</option>
          <option value="12">grudzień</option>
        </select>
      </div>
    </div>

    <small>Ilość wierszy: {{ numberOfRows }} <span v-if="totalRows>0">z {{ totalRows }}</span></small>

    <!--
    <input
      type="text"
      class="form-control mt-4"
      v-model="filterValue"
      @keyup="doFilter"
      placeholder="Filtr: wpisz fragment nazwy, adresu lub NIP"
    /> -->
  </div>

  <div class='w-100 text-end mb-3'>
    <router-link class="btn btn-outline-primary"
      :to="{ name: 'DocumentDetails', params: { kwd: this.$route.params.kwd, id: 0 } }">Dodaj dokument</router-link>
  </div>

  <div id="documents">
    <div class="row border-bottom" v-for="doc in documents" :key="doc.id_fv">
      <div class="col-md-3" title="Dokument obcy | data dokumentu obcego">
        <router-link class="fw-bold"
          :to="{ name: 'DocumentDetails', params: { kwd: this.$route.params.kwd, id: doc.id_fv } }">{{ doc.dokument_obcy
          }} z dnia {{ doc.data_dokumentu }}</router-link>
      </div>
      <div class="col-md-6" title="Nazwa wystawcy dokumentu | NIP&#10;Adres wystawcy dokumentu">
        <router-link v-if="doc.id_knt > 0" :to="{ name: 'ClientDetails', params: { id: doc.id_knt } }">
          {{ doc.nazwa_wystawcy }} <span v-if="doc.nip">| NIP: {{ doc.nip }}</span> <br />{{
            doc.adres_wystawcy
          }}
          {{ doc.adres_wystawcy_cd }}
        </router-link>
        <span v-if="doc.id_knt == 0">
          {{ doc.nazwa_wystawcy }} <span v-if="doc.nip">| NIP: {{ doc.nip }}</span> <br />{{
            doc.adres_wystawcy
          }}
          {{ doc.adres_wystawcy_cd }}
        </span>

      </div>
      <div class="col-md-3 text-end" title="Wartość brutto&#10;Wartość netto">
        <small>brutto:</small> {{ doc.wartosc_brutto }}<br /><small>netto:</small>
        {{ doc.wartosc_netto }}
      </div>
    </div>
  </div>
  <h2 v-if="documents != null && documents.length == 0">Brak elementów spełniających kryterium wyboru listy</h2>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast } from "@/services/Dialogs"

export default {
  name: "DocumentList",
  data() {
    return {
      documents: [],
      isError: false,
      filterValue: "",
      years: [],
      selectedYear: new Date().getFullYear(),
      selectedMonth: 0,//new Date().getMonth()+1,
      numberOfRows : 0,
      totalRows : 0
    };
  },

  created() {
    const currentYear = new Date().getFullYear();
    for (var a = currentYear; a > 2009; a--) {
      this.years.push(a);
    }
    console.log(this.years);
    this.fetchList();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchList();
    },

    handleChange() {
      this.fetchList();
    },

    fetchList() {
      fetchData(
        "?section=document&action=list",
        { mode: this.$route.params.kwd, filter: this.filterValue, year: this.selectedYear, month: this.selectedMonth },
        (res, info) => {
          this.documents = res;
          this.totalRows = Number(info);
          this.numberOfRows = res.length;
        },
        (err) => {
          if (err == null) err = "Błąd pobierania danych, zaloguj się ponownie";
          showToast(err, false);
          //document.getElementById("clients").innerHtml = "X";
          //$("#clients").html("Błąd");
        }
      );
    },
  },
};
</script>
