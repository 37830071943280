<template id="content">
  <div class="border-bottom p-1" v-if="data == null">
    <router-link to="/" class="me-2">Start</router-link>/
    <span class="me-2">Kontrahenci</span>
  </div>

  <div class="border rounded p-3 bg-light my-4">
    <h1 class="mt-2">Lista kontrahentów</h1>
    
    <div class="row">
      <div class="col-md-4">
      Fraza <!--@keyup="doFilter" -->
        <input type="text" class="form-control" v-model="filterValue" @keydown.enter="doFilter"
          placeholder="Filtr: wpisz fragment nazwy, adresu lub NIP" />
      </div>
      <div class="col-md-3">
        Transakcja
        <select class="form-select" v-model="tranType">
          <option value="">-dowolny lub bez transakcji-</option>
          <option value="k">kupno</option>
          <option value="d">dary</option>
          <option value="w">wymiana</option>
        </select>
      </div>

      <div class="col-md-2">
        Rok transakcji
        <select class="form-select" v-model="selectedYear">
          <option v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
      <div class="col-md-2">
        Miesiąc transakcji
        <select class="form-select" v-model="selectedMonth">
          <option value="">-dowolny-</option>
          <option value="1">styczeń</option>
          <option value="2">luty</option>
          <option value="3">marzec</option>
          <option value="4">kwiecień</option>
          <option value="5">maj</option>
          <option value="6">czerwiec</option>
          <option value="7">lipiec</option>
          <option value="8">sierpień</option>
          <option value="9">wrzesień</option>
          <option value="10">październik</option>
          <option value="11">listopad</option>
          <option value="12">grudzień</option>
        </select>
      </div>

      <div class='col-md-1'>
      <br/>
        <input
        type="button"
        class="btn btn-outline-primary "
        value="Szukaj"
        @click="fetchClients()"
      />
      </div>

    </div>
  </div>

  <div id="clients">
    <div class='w-100 text-end'>
      <router-link class="btn btn-outline-primary" :to="{ name: 'ClientDetails', params: { id: 0 } }">Utwórz
        kontrahenta</router-link>
    </div>
    <div class="row border-bottom" v-for="client in clients" :key="client.id_knt">
      <div class="col-md-6" title="Nazwa&#10;NIP">
        <router-link v-if="data == null" class="fw-bold"
          :to="{ name: 'ClientDetails', params: { id: client.id_knt } }">{{
            client.nazwa_wystawcy }}</router-link>
        <span v-if="data != null" @click="selectKnt(client)" class="text-primary hand fw-bold">{{
          client.nazwa_wystawcy }}
          [wybierz]</span>
        <br />{{ client.nip }}
      </div>
      <div class="col-md-6" title="Adres">
        {{ client.adres_wystawcy }}<br />{{ client.adres_wystawcy_cd }}
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast } from "@/services/Dialogs"

export default {
  name: "ClientList",
  data() {
    return {
      clients: [],
      isError: false,
      filterValue: "",
      tranType: "",
      years: [],
      selectedYear: "-dowolny-",
      selectedMonth: "",
    };
  },

  props: { //sprytne - dam tu obiekt
    data: {
      type: Object,
      default: null
    }
  },

  created() {
    const currentYear = new Date().getFullYear();
    this.years.push("-dowolny-");
    for (var a = currentYear; a > 2009; a--) {
      this.years.push(a);
    }
    this.fetchClients();
  },

  methods: {
    doFilter(event) {
      console.log("Key up event:", event);
      this.fetchClients();
    },

    selectKnt(client) {
      if (this.data.callback != null) this.data.callback(client);
    },

    fetchClients() {
      fetchData(
        "?section=client&action=list",
        { filter: this.filterValue,
          tranType : this.tranType,
          year : this.selectedYear,
          month: this.selectedMonth
         },
        (res) => {
          this.clients = res;
        },
        (err) => {
          showToast(err, false);
        }
      );
    },
  },
};
</script>
